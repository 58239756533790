<template>
  <div class="mt-3">
    <div class="text-center py-4" v-if="loadingOrgLogo">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div class="mx-4 mx-md-0 form__container pt-12" style="" v-else>
      <p class="info__text mx-4 mx-md-12">
        This logo will be shown in portal and emails you send to counterparties.
        Upload a logo representing your organization below
      </p>
      <v-form>
        <v-row class="mx-4 mx-md-10">
          <v-col>
            <v-card
              flat
              class="upload__container py-10 py-md-6 px-6 text-center"
              :class="{ dragging: dragging }"
              @click.native="onPickFile"
              @drop.prevent="onDroppedFiles"
              @dragover.prevent="dragging = true"
              @dragleave.prevent="dragging = false"
            >
              <p class="mb-0 primary--text font-weight-bold">Logo</p>
              <img
                v-if="attached_logo"
                :src="attached_logo"
                alt="Attached Logo"
                style="width: 200px; max-width: 100%"
              />
              <p v-else class="mb-0 primary--text font-weight-normal">
                Drop your logo here
              </p>
              <input
                type="file"
                accept="image/png"
                style="display: none"
                ref="hiddenFileInput"
                @change="onFilePicked"
              />
            </v-card>
          </v-col>
        </v-row>

        <div
          class="
            d-md-flex
            justify-end
            align-center
            pt-4
            pb-6
            px-6
            pa-md-8
            mx-md-4
          "
        >
          <v-btn
            class="submit-btn"
            :loading="isSaving"
            dark
            :width="$vuetify.breakpoint.smAndDown ? '100%' : '121'"
            style="box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.24)"
            color="primary"
            height="45"
            @click="uploadLogo"
          >
            <simple-line-icons
              icon="arrow-right"
              color="#FFFFFF"
              style="
                font-family: simple-line-icons;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
              "
              no-svg
            />
            <span class="btn-name text-capitalize pl-3">Upload</span>
          </v-btn>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "BrandingTab",
  data() {
    return {
      isSaving: false,
      loadingOrgLogo: false,
      dragging: false,
      attached_logo: null,
    };
  },
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),

    // Handle the click event on the upload container
    onPickFile() {
      this.$refs.hiddenFileInput.click();
    },
    // Handle the file input change event
    onFilePicked(event) {
      const selectedFile = event.target.files[0];

      // Validate the file extension
      if (!this.isValidFileExtension(selectedFile)) {
        // Display an error message
        this.showToast({
          sclass: "error",
          show: true,
          message: "Please select a PNG file.",
          timeout: 3000,
        });
        return; // Exit the method without setting attached_logo
      }

      // Set the attached_logo only if it's a valid PNG file
      this.attached_logo = URL.createObjectURL(selectedFile);
    },
    // Handle the drop event when files are dropped
    onDroppedFiles(event) {
      event.preventDefault();
      this.dragging = false;
      this.attached_logo = URL.createObjectURL(event.dataTransfer.files[0]);
    },
    // Handle the upload button click event
    uploadLogo() {
      // Add your logic to upload the logo
    },

    // Validate the file extension
    isValidFileExtension(file) {
      const allowedExtensions = ["png"];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      return allowedExtensions.includes(fileExtension);
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      AllBanks: "transactions/getAllBanks",
      organizationId: "auth/organizationId",
    }),

    // check and get the brand logo global variable
    attachedLogoFromAppSettings() {
      const appSettings = this.$appSettings;

      if (appSettings && appSettings.brand_logo) {
        return appSettings.brand_logo;
      }

      return null; // If brand_logo is not available in $appSettings
    },
  },
  created() {
    // Set attached_logo using $appSettings.brand_logo if available
    this.attached_logo = this.attachedLogoFromAppSettings || this.attached_logo;
  },
  mounted() {},
  watch: {},
};
</script>
<style scoped>
.save-btn {
  background: var(--v-primary-base);
  box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
}

.btn-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.636364px;
  color: #ffffff;
}

.simpleLineIcon {
  font-family: "simple-line-icons";
  margin-top: 0.2em;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  transform: rotateY(3.142rad);
}

.upload__container {
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px dashed rgba(127, 145, 155, 0.5519);
  cursor: pointer;
}

.upload__container:hover {
  background-color: #fcfdfd;
}

.form__container {
  max-width: 100%;
  background-color: white;
}

.info__text {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-family: "Inter";
}

/* Styles for desktop screens */
@media screen and (min-width: 1024px) {
  .form__container {
    max-width: 60%;
    /* Adjust the max-width as needed */
    margin: 0 0;
    /* Center the container horizontally */
  }
}

.upload__container.dragging {
  border: 1px dashed #424f95;
  /* Change border color when dragging */
}
</style>
