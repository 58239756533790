<template>
  <v-card
    height="40px"
    width="100%"
    flat
    class="d-flex"
    style="background: rgba(127, 145, 155, 0.052607)"
  >
    <v-spacer></v-spacer>
  </v-card>
</template>

<script>
export default {};
</script>

<style scoped>
.v-application .elevation-3 {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%), 0px 6px 10px 0px rgb(0 0 0 / 3%),
    0px 1px 18px 0px rgb(0 0 0 / 3%) !important;
}
</style>
