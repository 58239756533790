<template>
  <div class="mt-3">
    <div class="text-center py-4" v-if="loadingBusinessDetails">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div class="form__container pt-2 pt-md-4" v-else>
      <v-form ref="businessForm">
        <!-- Business Information -->
        <v-row class="mx-4 mx-md-10">
          <v-col cols="12" md="6">
            <label>Business Name</label>
            <v-text-field
              v-model="BusinessDetails.business_name"
              background-color="#ffffff"
              hide-details="auto"
              outlined
              placeholder="Business Name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <label>Reg. Number</label>
            <v-text-field
              v-model="BusinessDetails.reg_no"
              background-color="#ffffff"
              hide-details="auto"
              outlined
              placeholder="Registration Number"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <label>Office Address</label>
            <v-text-field
              v-model="BusinessDetails.office_address"
              background-color="#ffffff"
              hide-details="auto"
              outlined
              placeholder="Office Address"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <label>Country</label>
            <v-select
              v-model="BusinessDetails.country"
              background-color="#ffffff"
              hide-details="auto"
              :items="countries"
              outlined
              placeholder="Country"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <label>State</label>
            <v-text-field
              v-model="BusinessDetails.state"
              background-color="#ffffff"
              hide-details="auto"
              outlined
              placeholder="State"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <label>City</label>
            <v-text-field
              v-model="BusinessDetails.city"
              background-color="#ffffff"
              hide-details="auto"
              outlined
              placeholder="City"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <label>Post Code</label>
            <v-text-field
              v-model="BusinessDetails.postcode"
              background-color="#ffffff"
              hide-details="auto"
              outlined
              placeholder="Post Code"
            ></v-text-field>
          </v-col>
        </v-row>
        <div class="py-10 px-4 px-md-13">
          <hr class="dotted-line" />
        </div>
        <!-- Leadership Information -->
        <p class="mx-4 mx-md-13 header__title">Leadership</p>
        <v-row class="mx-4 mx-md-10">
          <v-col cols="12" md="6">
            <label>First Name</label>
            <v-text-field
              v-model="BusinessDetails.leadership.first_name"
              background-color="#ffffff"
              hide-details="auto"
              outlined
              placeholder="First Name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <label>Last Name</label>
            <v-text-field
              v-model="BusinessDetails.leadership.last_name"
              background-color="#ffffff"
              hide-details="auto"
              outlined
              placeholder="Last Name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <label>Email</label>
            <v-text-field
              v-model="BusinessDetails.leadership.email"
              background-color="#ffffff"
              hide-details="auto"
              outlined
              placeholder="Email"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <label>Phone</label>
            <v-text-field
              v-model="BusinessDetails.leadership.phone"
              background-color="#ffffff"
              hide-details="auto"
              outlined
              placeholder="Phone"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <label>BVN</label>
            <v-text-field
              v-model="BusinessDetails.leadership.bvn"
              background-color="#ffffff"
              hide-details="auto"
              outlined
              placeholder="BVN"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <label>DOB</label>
            <v-text-field
              v-model="BusinessDetails.leadership.dob"
              background-color="#ffffff"
              hide-details="auto"
              outlined
              placeholder="DOB"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- Submit Button -->
        <div class="d-flex justify-end py-6 mx-md-10 px-md-3">
          <v-btn
            @click="updateBusinessDetails"
            class="submit-btn"
            :loading="isSaving"
            dark
            color="primary"
            height="45"
          >
            <simple-line-icons
              icon="arrow-right"
              color="#FFFFFF"
              style="
                font-family: simple-line-icons;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
              "
              no-svg
            />
            <span class="btn-name text-capitalize pl-3">Submit</span>
          </v-btn>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "BusinessTab",
  data() {
    return {
      BusinessDetails: {
        business_name: "",
        reg_no: "",
        office_address: "",
        country: "",
        state: "",
        city: "",
        postcode: "",
        leadership: {
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          bvn: "",
          dob: "",
        },
      },
      isSaving: false,
      loadingBusinessDetails: false,
      countries: ["Ghana", "Nigeria", "USA"], // Replace with your actual countries
    };
  },
  methods: {
    ...mapActions(["showToast"]),
    async updateBusinessDetails() {
      try {
        this.isSaving = true;

        await this.$store.dispatch(
          "organizations/updateOrganization",
          this.organizationPayload
        );

        this.showToast({
          sclass: "success",
          show: true,
          message: "organization Details Updated successfully",
          timeout: 3000,
        });
      } catch (err) {
        this.showToast({
          sclass: "error",
          show: true,
          message: err.msg,
          timeout: 3000,
        });
      } finally {
        this.isSaving = false;
      }
    },

    async fetchOrganizationDetails(value) {
      this.loadingBusinessDetails = true;
      try {
        const data = await this.$store.dispatch(
          "organizations/getOrganizationById",
          value
        );

        this.BusinessDetails.business_name = data.company?.company_name;
        this.BusinessDetails.reg_no = data.company?.registration_number;
        this.BusinessDetails.office_address = data.office[0]?.address;
        this.BusinessDetails.country = data.office[0]?.country;
        this.BusinessDetails.state = data.office[0]?.state;
        this.BusinessDetails.city = data.office[0]?.city;
        this.BusinessDetails.postcode = data.office[0]?.postcode;
        this.BusinessDetails.leadership.first_name =
          data.leadership?.first_name;
        this.BusinessDetails.leadership.last_name = data.leadership?.last_name;
        this.BusinessDetails.leadership.email = data.leadership?.email;
        this.BusinessDetails.leadership.phone = data.leadership?.phone;
        this.BusinessDetails.leadership.bvn = data.leadership?.bvn;
        this.BusinessDetails.leadership.dob = data.leadership?.dob;
      } catch (error) {
        this.showToast({
          sclass: "error",
          show: true,
          message: error.msg,
          timeout: 3000,
        });
      } finally {
        this.loadingBusinessDetails = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      AllBanks: "transactions/getAllBanks",
      organizationId: "auth/organizationId",
    }),

    organizationPayload() {
      return {
        company: {
          company_name: this.BusinessDetails.business_name,
          registration_number: this.BusinessDetails.reg_no,
        },
        office: [
          {
            country: this.BusinessDetails.country,
            state: this.BusinessDetails.state,
            city: this.BusinessDetails.city,
            postcode: this.BusinessDetails.postcode,
          },
        ],
        leadership: [
          {
            first_name: this.BusinessDetails.leadership.first_name,
            last_name: this.BusinessDetails.leadership.last_name,
            email: this.BusinessDetails.leadership.email,
            phone: this.BusinessDetails.leadership.phone,
          },
        ],
      };
    },
  },

  watch: {
    organizationId: {
      handler(value) {
        this.fetchOrganizationDetails(value);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.section-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}

.v-subheader {
  align-items: center;
  display: flex;
  height: 23px;
  font-size: 0.98rem;
  font-weight: 500;
}

.save-btn {
  background: var(--v-primary-base);
  box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
}

.btn-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.636364px;
  color: #ffffff;
}

.simpleLineIcon {
  font-family: "simple-line-icons";
  margin-top: 0.2em;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  transform: rotateY(3.142rad);
}

.form__info {
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  color: rgba(25, 40, 61, 0.8);
}

label {
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgba(25, 40, 61, 0.8);
}

.link__text {
  font-family: "Inter";
  font-weight: 700;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}

.form__container {
  max-width: 100%;
  background-color: white;
}

.header__title {
  font-family: "Inter";
  font-weight: 700;
  font-size: 12px;
}

.dotted-line {
  border-top: 1px dotted #ccc;
}

/* Styles for desktop screens */
@media screen and (min-width: 1024px) {
  .form__container {
    max-width: 60%;
    /* Adjust the max-width as needed */
    margin: 0 0;
    /* Center the container horizontally */
  }
}
</style>
