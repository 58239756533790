<template>
  <div class="mt-3">
    <div class="text-center py-4" v-if="loadingUserDetails">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div class="mx-4 mx-md-0 form__container" style="" v-else>
      <v-form>
        <v-row class="mx-4 mx-md-10">
          <v-col cols="12" md="6">
            <label>First Name</label>
            <v-text-field
              ref="firstName"
              v-model="userDetails.first_name"
              background-color="#ffffff"
              outlined
              hide-details="auto"
              placeholder="First Name"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <label>Last Name</label>
            <v-text-field
              ref="lastName"
              v-model="userDetails.last_name"
              background-color="#ffffff"
              outlined
              hide-details="auto"
              placeholder="Last Name"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <label>Email</label>
            <v-text-field
              ref="email"
              v-model="userDetails.email"
              background-color="#ffffff"
              outlined
              hide-details="auto"
              placeholder="Email"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <label>Phone</label>
            <v-text-field
              ref="phone"
              v-model="userDetails.phone"
              background-color="#ffffff"
              outlined
              hide-details="auto"
              placeholder="Phone Number"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- <div v-for="(account, index) in userDetails.bankAccounts" :key="index" class="mx-4 mx-md-10 px-3"> -->
        <div class="mx-4 mx-md-10 px-3">
          <v-row class="">
            <v-col cols="12" md="6">
              <label>Bank Name</label>
              <v-select
                :rules="[(v) => !!v || 'Bank name is required']"
                :items="AllBanks"
                item-text="bank"
                item-value="code"
                return-object
                background-color="#ffffff"
                outlined
                hide-details="auto"
                placeholder="select one"
                v-model="userDetails.bank"
                append-icon="mdi-magnify"
                :menu-props="{ searchable: true, lazy: true }"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <label>Account Number</label>
              <v-text-field
                v-model="userDetails.bank.accountnumber"
                background-color="#ffffff"
                outlined
                hide-details="auto"
                placeholder="Bank Account"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <!-- <div class="d-flex justify-space-between mx-md-4 mx-md-14 mb-2">
          <p class="link__text text--primary" @click="addAnotherRow">
            + add another
          </p>
          <p class="link__text" style="color: red" @click="removeLastRow" v-if="userDetails.bankAccounts.length > 1">
            - remove last
          </p>
        </div> -->
        <div
          class="
            d-md-flex
            justify-space-between
            align-center
            pt-4
            pb-6
            px-6
            pa-md-8
            mx-md-4
          "
        >
          <p v-if="isResetSending">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </p>
          <p
            v-else
            class="link__text text--primary"
            @click.once="resetPassword"
          >
            Reset Password
          </p>
          <v-btn
            @click="updateUserDetails"
            class="submit-btn"
            :loading="isSaving"
            dark
            :width="$vuetify.breakpoint.smAndDown ? '100%' : '121'"
            style="box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.24)"
            color="primary"
            height="45"
          >
            <simple-line-icons
              icon="arrow-right"
              color="#FFFFFF"
              style="
                font-family: simple-line-icons;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
              "
              no-svg
            />
            <span class="btn-name text-capitalize pl-3">Submit</span>
          </v-btn>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import auth from "@/api/auth";

export default {
  name: "ProfileTab",
  data() {
    return {
      userDetails: {
        username: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        // bankAccounts: [
        //   {
        //     bankname: "",
        //     accountnumber: "",
        //   },
        // ],
        bank: {
          bankcode: "",
          bankname: "",
          accountname: "",
          accountnumber: "",
          entity: "",
        },
      },
      isSaving: false,
      isResetSending: false,
      loadingUserDetails: false,
    };
  },
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),

    async updateUserDetails() {
      try {
        this.isSaving = true;

        await this.$store.dispatch(
          "organizations/updateUserProfile",
          this.userPayload
        );

        this.showToast({
          sclass: "success",
          show: true,
          message: "User Details Updated successfully",
          timeout: 3000,
        });
      } catch (err) {
        this.showToast({
          sclass: "error",
          show: true,
          message: err.msg,
          timeout: 3000,
        });
      } finally {
        this.isSaving = false;
      }

      // console.log(JSON.stringify(this.userPayload, null, 2));
    },

    // Get the user details
    async fetchUserDetails() {
      this.loadingUserDetails = true;
      try {
        const user = await this.$store.dispatch("auth/fetchUserDetails");

        this.userDetails.username = user.username;
        this.userDetails.first_name = user.first_name;
        this.userDetails.last_name = user.last_name;
        this.userDetails.email = user.email;
        this.userDetails.phone = user.phone;
        if (user.bank) {
          this.userDetails.bank.bank = user.bank.bankname;
          this.userDetails.bank.accountnumber = user.bank.accountnumber;
          this.userDetails.bank.code = user.bank.bankcode;
        }

        // console.log(JSON.stringify(user, null, 2));
      } catch (error) {
        this.showToast({
          sclass: "error",
          show: true,
          message: error.msg,
          timeout: 3000,
        });
      } finally {
        this.loadingUserDetails = false;
      }
    },

    async fetchAllBanks() {
      try {
        await this.$store.dispatch("transactions/getAllBankNamesAndCodes");
      } catch (error) {
        console.log(error);
      }
    },

    // method for the reset-password link
    async resetPassword() {
      const payload = {
        email: this.userDetails.email,
      };

      try {
        this.isResetSending = true;
        auth.forgotPassword(payload).then(
          this.showToast({
            sclass: "success",
            show: true,
            message: "Email Sent, Please Check Your Email",
            timeout: 5000,
          })
        );

        setTimeout(() => {
          this.$router.replace("/login");
        }, 3000);
      } catch (error) {
        this.showToast({
          sclass: "error",
          show: true,
          message: error.msg,
          timeout: 3000,
        });
      } finally {
        this.isResetSending = false;
      }
    },

    // add another row of bank
    // addAnotherRow() {
    //   console.log("clicked");
    //   this.userDetails.bankAccounts.push({
    //     bankname: "",
    //     accountnumber: "",
    //   });
    // },

    // remove the last row of bank
    // removeLastRow() {
    //   if (this.userDetails.bankAccounts.length > 1) {
    //     this.userDetails.bankAccounts.pop();
    //   }
    // },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      AllBanks: "transactions/getAllBanks",
      organizationId: "auth/organizationId",
    }),

    userPayload() {
      return {
        username: this.userDetails.username,
        email: this.userDetails.email,
        first_name: this.userDetails.first_name,
        last_name: this.userDetails.last_name,
        phone: this.userDetails.phone,
        bank: {
          bankname: this.userDetails.bank.bank,
          accountnumber: this.userDetails.bank.accountnumber,
          bankcode: this.userDetails.bank.code,
        },
      };
    },
  },

  mounted() {
    this.fetchAllBanks();
    this.fetchUserDetails();
  },

  watch: {
    // organizationId: {
    //   handler(value) {
    //     this.fetchUserDetails(value);
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
};
</script>

<style scoped>
.section-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}

.v-subheader {
  align-items: center;
  display: flex;
  height: 23px;
  font-size: 0.98rem;
  font-weight: 500;
}

.save-btn {
  background: var(--v-primary-base);
  box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
}

.btn-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.636364px;
  color: #ffffff;
}

.simpleLineIcon {
  font-family: "simple-line-icons";
  margin-top: 0.2em;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  transform: rotateY(3.142rad);
}

.form__info {
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  color: rgba(25, 40, 61, 0.8);
}

label {
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgba(25, 40, 61, 0.8);
}

.link__text {
  font-family: "Inter";
  font-weight: 700;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}

.form__container {
  max-width: 100%;
  background-color: white;
}

/* Styles for desktop screens */
@media screen and (min-width: 1024px) {
  .form__container {
    max-width: 60%;
    /* Adjust the max-width as needed */
    margin: 0 0;
    /* Center the container horizontally */
  }
}
</style>
